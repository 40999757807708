import { StreamGradientLogo } from './StreamGradientLogo'
import { StreamUserLogo } from './StreamUserLogo'
import { StreamLogoProps } from './types'
import { VARIANTS } from './variants'

export const StreamLogo = ({ variant = 'primary', ...props }: StreamLogoProps) => {
  const { fill, paintId, clipPath } = VARIANTS[variant]

  if (variant === 'primary' || variant === 'secondary') {
    return (
      <StreamGradientLogo
        fill={fill}
        paintId={paintId}
        variant={variant}
        clipPath={clipPath}
        {...props}
      />
    )
  }

  return (
    <StreamUserLogo
      fill={fill}
      paintId={paintId}
      variant={variant}
      clipPath={clipPath}
      {...props}
    />
  )
}
